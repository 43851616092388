import { Component, Vue, Watch } from 'vue-property-decorator'

import { api, getURL } from '@/services/api'
import { Alert } from '@/services/notification'

import { LicitacaoService } from '@/services/licitacaoService'

//components
import PortalAdminContent from '@/components/portalAdminContent2/PortalAdminContent2.vue'

//warppets
import { 
  wIcon,
  Breadcrumb
} from '@/components/wrappers';

@Component({
  components: {
    wIcon,
    PortalAdminContent,
    Breadcrumb
  }
})
export default class Licitacoes extends Vue {

  licitacaoService = new LicitacaoService(this.$store);

  alert = new Alert(this.$store)
  isLoading = false
  list = []
  columns = [
    { field: 'idLicitacao', label: 'ID' },
    { field: 'titulo', label: 'Titulo' },
    { field: 'objeto', label: 'Objeto' },
    { field: 'fksituacao', label: 'Situação' },
    { field: 'dataabertura', label: 'Data de Abertura' },
    { field: 'datapublicacao', label: 'Data de Publicação' }
  ]
  filtro: any = {
    status: '',
    fkmodalidade: ''
  };
  pagination: any = {
    _page: 1,
    _limit: 10,
    _orderBy: 'idLicitacao',
    _order: 'desc'
  };
  bpagination = 1
  totalList = 0
  isOpenFiltro = false
  isShowFiltro = false
  activeTab = 0;

  mounted() {
    if( this.$route.query._page != undefined ){
      this.pagination._page = this.$route.query._page;
    }
    if( this.$route.query.modalidade != undefined ){
      this.filtro.fkmodalidade = this.$route.query.modalidade;
    }
    if( this.$route.query.titulo != undefined ){
      this.filtro.titulo = this.$route.query.titulo;
    }
    if( this.$route.query.objeto != undefined ){
      this.filtro.objeto = this.$route.query.objeto;
    }
    if( this.$route.query.status != undefined ){
      this.filtro.status = this.$route.query.status;
    }

    this.setSelectedTab();
    
    const query = this.mountUrlQuery();
    this.getList(query)

    this.isShowFiltro = this.getFiltroStatus()
  }

  clickEdit(item: any){
    // console.log(item);
    this.$router.push('/transparencia/licitacoes/'+item.idLicitacao)
  }

  clickDelete(item: any){
    const onConfirm = (item: any) => {
      console.log(item);
    }
    this.alert.dialogConfirm('Deseja continuar com esta operação?', onConfirm)
  }

  clickBtnLimparFiltros(){
    this.activeTab = 0;
    this.filtro = {
      status: '',
      fkmodalidade: ''
    }
    this.$router.push({query: { _page: '1'} })
    const query = this.mountUrlQuery();
    this.getList(query)
  }

  clickCloseFiltro(){
    // this.isShowFiltro = false;
    this.toggleFiltro()
    this.clickBtnLimparFiltros();
  }

  toggleFiltro(){
    this.isShowFiltro = !this.isShowFiltro
    window.sessionStorage.setItem('toggleFiltro', this.isShowFiltro+'')
  }

  getFiltroStatus(){
    const test = window.sessionStorage.getItem('toggleFiltro');
    if(test == null){
      return false;
    }
    else if(test == 'true'){
      return true;
    }
    return false;
  }

  mountUrlQuery(){
    let query = this.mountUrlQueryPagination();
    query = query + '&' + this.mountUrlQueryFiltro();
    return query;
  }

  mountUrlQueryPagination(){
    let str  = '';
    let i = 1;
    for(const key in this.pagination){
      str = str + key + '=' + this.pagination[key];
      if( i >= 1 && i < Object.keys(this.pagination).length){
        str = str + '&'
      }
      i = i + 1;
    }
    return str;
  }
  
  mountUrlQueryFiltro(){
    let str  = '';
    let i = 1;
    for(const key in this.filtro){
      if( this.filtro[key] != '' ){
        if(key == 'objeto'){
          str = str + `objeto=%25${this.filtro[key]}%25`;
        }
        if(key == 'titulo'){
          str = str + `titulo=%25${this.filtro[key]}%25`;
        }
        // if(key == 'titulo'){
        //   str = str + 'titulo_like' + '=' + this.filtro[key];
        // }
        else{
          str = str + key + '=' + this.filtro[key];
        }
        // adiciona caracter & na query
        if( i >= 1 && i < Object.keys(this.filtro).length){
          str = str + '&'
        }
        console.log(str);
        
      }
      i = i + 1;
    }
    return str;
  }

  changePagination(param: any){
    
    this.$router.push({query: { ...this.$route.query,_page: param } })
    this.pagination._page = param;
    const query = this.mountUrlQuery();
    this.getList(query);
  }

  clickBtnProcurar(){
    // set url query
    const urlQuery: any = {}
    this.pagination._page = 1;
    urlQuery._page = '1'
    if(this.filtro.fkmodalidade != ''){
      urlQuery.modalidade = this.filtro.fkmodalidade
    }
    if(this.filtro.titulo != ''){
      urlQuery.titulo = this.filtro.titulo
    }
    if(this.filtro.objeto != ''){
      urlQuery.objeto = this.filtro.objeto
    }
    if(this.filtro.status != ''){
      urlQuery.status = this.filtro.status
    }
    this.$router.push({query: urlQuery })
    // mount query to send in request
    const query = this.mountUrlQuery();
    // console.log(query);
    // send request
    this.getList(query);
  }

  getList(query: string){
    this.isLoading = true;
    const success = (response: any) => {
      this.bpagination = response.data.page;
      this.list = this.licitacaoService.processListReceive(response.data.list)
      this.totalList = response.data.totalitems
    }
    const error = (error: any) => {
      console.log(error);
    }
    const url = '/licitacao?'+query;
    api.get(url)
      .then(success)
      .catch(error)
      .finally(()=>{this.isLoading=false})
  }

  clickTab(num: number){
    this.activeTab = num;

    // set url query
    this.pagination._page = 1;

    if(this.activeTab == 0 ){
      this.filtro.fkmodalidade = ''
    }
    else if(this.activeTab == 1 ){
      this.filtro.fkmodalidade = '2'
    }
    else if(this.activeTab == 2 ){
      this.filtro.fkmodalidade = '3'
    }
    else if(this.activeTab == 3 ){
      this.filtro.fkmodalidade = '4'
    }
    else if(this.activeTab == 4 ){
      this.filtro.fkmodalidade = '11'
    }
    this.clickBtnProcurar();
  }

  setSelectedTab(){
    if(this.$route.query.modalidade == undefined){
      this.activeTab = 0;
    }
    else if (this.$route.query.modalidade == '2'){
      this.activeTab = 1;
    }
    else if (this.$route.query.modalidade == '3'){
      this.activeTab = 2;
    }
    else if (this.$route.query.modalidade == '4'){
      this.activeTab = 3;
    }
  }
}
